export default {
  namespaced: true,
  state: () => ({
    list: [],
    item: null,
    show: false,
  }),
  getters: {},
  mutations: {
    RESET_SNACKBAR(state) {
      state.item = null;
      state.show = false;
    },
    SET_SNACKBAR(state, data) {
      state.item = data;
      state.show = true;
    },
  },
  actions: {
    SHOW({ commit, dispatch }, payload) {
      commit("RESET_SNACKBAR");
      commit("SET_SNACKBAR", payload);
      return payload;
    },
  },
};
